import React, { useEffect, useState } from "react";
import { templateLettersApi } from "src/api";
import { TemplateLetter } from "src/interfaces/templateLetter";

interface TemplateLetterViewerProps {
  template: TemplateLetter;
  userID: number;
  propertyID?: number;
  displayBadge?: boolean;
}

const SkeletonLine = ({ width }: { width: string }) => (
  <div
    className={`h-2 animate-shimmer rounded-full bg-gradient-to-r from-gray-300 via-gray-100 to-gray-200 bg-[length:200%_100%] ${width}`}
  />
);

export const TemplateLetterViewer: React.FC<TemplateLetterViewerProps> = ({
  template,
  userID,
  propertyID,
  displayBadge = true,
}) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [previewUrl, setPreviewUrl] = useState<string>("");

  useEffect(() => {
    const loadPreview = async () => {
      try {
        setLoading(true);
        const response = await templateLettersApi.preview(
          template.id,
          userID,
          false,
          propertyID
        );
        setPreviewUrl(response);
      } catch (error) {
        console.error("Failed to load preview:", error);
        setLoading(false);
      }
    };

    loadPreview();
  }, [template.id, userID, propertyID]);

  const handleImageLoad = () => {
    console.log("Image loaded successfully");
    setLoading(false);
  };

  const handleImageError = () => {
    console.error("Failed to load image");
    setLoading(false);
  };

  return (
    <>
      <div
        className="relative min-h-[3.67in] w-[2.83in] cursor-pointer bg-white shadow-md transition-shadow hover:shadow-lg"
        onClick={() => setIsZoomed(true)}
      >
        {displayBadge && (
          <div className="badge badge-secondary badge-lg absolute -left-[6px] -top-[10px] z-30 font-semibold text-white">
            {template.name}
          </div>
        )}

        {/* Loading State */}
        {loading && (
          <div className="absolute inset-0 z-20 bg-white p-6">
            {/* Letter content skeleton */}
            <div className="space-y-4">
              {/* Header section */}
              <div className="space-y-2">
                <SkeletonLine width="w-1/2" />
                <SkeletonLine width="w-1/3" />
              </div>

              {/* Date */}
              <div className="pt-4">
                <SkeletonLine width="w-1/4" />
              </div>

              {/* Address block */}
              <div className="space-y-1 pt-4">
                <SkeletonLine width="w-2/3" />
                <SkeletonLine width="w-1/2" />
                <SkeletonLine width="w-2/5" />
              </div>

              {/* Main content */}
              <div className="space-y-2 pt-6">
                <SkeletonLine width="w-full" />
                <SkeletonLine width="w-11/12" />
                <SkeletonLine width="w-full" />
                <SkeletonLine width="w-3/4" />
              </div>

              {/* Signature area */}
              <div className="space-y-2 pt-8">
                <SkeletonLine width="w-1/4" />
                <SkeletonLine width="w-1/3" />
              </div>
            </div>
          </div>
        )}

        {/* Image */}
        {previewUrl && (
          <img
            src={previewUrl}
            alt="Template preview"
            className="block h-auto w-full"
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
        )}
      </div>

      {/* Zoom Modal */}
      {isZoomed && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4"
          onClick={() => setIsZoomed(false)}
        >
          <div
            className="relative max-h-[90vh] max-w-[90vw] overflow-auto rounded-lg bg-white"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="absolute right-2 top-2 rounded-full bg-white p-2 hover:bg-gray-100"
              onClick={() => setIsZoomed(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <img
              src={previewUrl}
              alt="Template preview"
              className="h-auto w-full"
            />
          </div>
        </div>
      )}
    </>
  );
};

// import React from "react";
import { Link } from "react-router-dom";
import { authChannel } from "src/channels/authChannel";
import "./SideNavItems.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faGauge,
  faUserGroup,
  faGear,
  faEnvelope,
  faArrowRightFromBracket,
  faHouse,
  faHeadset,
  faCar,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";
import useAuth from "src/hooks/useAuth";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";
import useNav from "src/hooks/useNav";
import useTeamContext from "src/hooks/private/useTeamContext";
import SubscriptionsHelp from "src/components/help/SubscriptionsHelp";
import UpgradeModal from "src/components/modals/UpgradeModal";
import useModal from "src/hooks/useModal";
import SubscriptionBadge from "src/components/badges/SubscriptionBadge";
import useSubscriptionContext from "src/hooks/useSubscription";
import getNextSubscription from "src/helpers/getNextSubscription";
import { faComment } from "@fortawesome/free-regular-svg-icons";
import { faRocket } from "@fortawesome/pro-light-svg-icons";
import { useLocation } from "react-router-dom";
import useCurrentSubscriptionContext from "src/contexts/private/useCurrentSubscriptionContext";
import useApp from "src/hooks/useAppContext";
import { ReactNode } from "react";
import NewBadge from "src/components/badges/NewBadge";

interface NavItem {
  title: string;
  route: string;
  icon: IconProp;
  isExternal: boolean;
  onClick?: () => void;
  display?: boolean;
  isDisabled?: boolean;
  badge?: ReactNode | null;
}

const NavLinkItem = ({
  title,
  route,
  icon,
  onClick,
  isExternal,
  isDisabled = false,
  badge,
}: NavItem) => {
  const location = useLocation();

  const formattedRoute = route.endsWith("/") ? route : `${route}/`;
  const formattedPathname = location.pathname.endsWith("/")
    ? location.pathname
    : `${location.pathname}/`;
  const isActive = formattedPathname === formattedRoute;

  return (
    <>
      {isExternal ? (
        <a href={route} target="_blank" rel="noreferrer">
          <div
            onClick={onClick}
            className={classNames({
              "mb-3 rounded-lg bg-gradient-to-b from-primary to-accent px-10 py-3 shadow-lg shadow-icon-light dark:shadow-back-dark":
                isActive,
              "mb-1 rounded-lg px-10 py-3 hover:bg-gray-200 dark:hover:bg-back-dark lg:mb-3":
                !isActive,
            })}
          >
            <FontAwesomeIcon
              icon={icon}
              className={classNames({
                "mr-1.5 text-xl text-text-light dark:text-text-dark": isActive,
                "mr-1.5 text-xl text-icon-light dark:text-icon-dark": !isActive,
              })}
            />
            <span
              className={classNames({
                "text-xl font-bold text-text-light dark:text-text-dark":
                  isActive,
                "text-xl font-bold text-icon-light dark:text-icon-dark":
                  !isActive,
              })}
            >
              {" "}
              {title}
            </span>
          </div>
        </a>
      ) : isDisabled ? (
        <div
          onClick={onClick}
          className={classNames({
            "mb-3 cursor-pointer rounded-lg bg-gradient-to-r from-gradient1 to-gradient2 px-10 py-3 shadow-lg shadow-icon-light dark:shadow-back-dark relative":
              isActive,
            "mb-1 cursor-pointer rounded-lg px-10 py-3 hover:bg-gray-200 dark:hover:bg-back-dark lg:mb-3 relative":
              !isActive,
          })}
        >
          {badge && (
            <div className="absolute right-[8px] top-[7px] transition-none md:right-[10px] md:top-[16px]">
              {badge}
            </div>
          )}
          <FontAwesomeIcon
            icon={icon}
            className={classNames({
              "mr-1.5 text-xl text-text-light dark:text-text-dark": isActive,
              "mr-1.5 text-xl text-icon-light dark:text-icon-dark": !isActive,
            })}
          />
          <span
            className={classNames({
              "text-xl font-bold text-text-light dark:text-text-dark": isActive,
              "text-xl font-bold text-icon-light dark:text-icon-dark":
                !isActive,
            })}
          >
            {" "}
            {title}
          </span>
        </div>
      ) : (
        <Link to={route}>
          <div
            onClick={onClick}
            className={classNames({
              "relative mb-1 rounded-lg bg-gradient-to-r from-gradient1 to-gradient2 px-10 py-1 shadow-lg shadow-icon-light dark:shadow-back-dark lg:mb-3 lg:py-3":
                isActive,
              "relative mb-1 rounded-lg px-10 py-1 hover:bg-gray-200 dark:hover:bg-back-dark lg:mb-3 lg:py-3":
                !isActive,
            })}
          >
            <FontAwesomeIcon
              icon={icon}
              className={classNames({
                "mr-1.5 text-xl text-text-light dark:text-text-dark": isActive,
                "mr-1.5 text-xl text-icon-light dark:text-icon-dark": !isActive,
              })}
            />
            <span
              className={classNames({
                "text-xl font-bold text-text-light dark:text-text-dark":
                  isActive,
                "text-xl font-bold text-icon-light dark:text-icon-dark":
                  !isActive,
              })}
            >
              {" "}
              {title}
            </span>
            {badge && (
              <div className="absolute right-[8px] top-[7px] transition-none md:right-[10px] md:top-[16px]">
                {badge}
              </div>
            )}
          </div>
        </Link>
      )}
    </>
  );
};

const LinkItem = ({
  title,
  route,
  icon,
  onClick,
  isExternal,
  isDisabled = false,
}: NavItem) => {
  return (
    <>
      {isExternal ? (
        <a
          href={route}
          className="mb-4 px-10 py-3"
          target={"_blank"}
          rel="noreferrer"
        >
          <FontAwesomeIcon
            icon={icon}
            className="text-md mr-1.5 text-icon-light dark:text-icon-dark"
          />
          <span className="text-md font-bold text-icon-light dark:text-icon-dark">
            {" "}
            {title}
          </span>
        </a>
      ) : isDisabled ? (
        <>
          {" "}
          <FontAwesomeIcon
            icon={icon}
            className="text-md mr-1.5 text-icon-light dark:text-icon-dark"
          />
          <span className="text-md font-bold text-icon-light dark:text-icon-dark">
            {" "}
            {title}
          </span>
        </>
      ) : (
        <Link to={route} onClick={onClick} className="mb-4 px-10 py-3">
          <FontAwesomeIcon
            icon={icon}
            className="text-md mr-1.5 text-icon-light dark:text-icon-dark"
          />
          <span className="text-md font-bold text-icon-light dark:text-icon-dark">
            {" "}
            {title}
          </span>
        </Link>
      )}
    </>
  );
};

export const SideNavItems = () => {
  const { logout } = useAuth();

  const { setOpen } = useNav();

  const { team } = useTeamContext();

  const { openModalWith } = useModal();

  const { app } = useApp();

  const handleLogout = () => {
    authChannel.postMessage("logout");
    logout();
  };

  const { appSubscriptions } = useSubscriptionContext();

  const { subscription } = useCurrentSubscriptionContext();

  const closeNav = () => {
    setOpen(false);
  };

  const handleUpgrade = () => {
    openModalWith({
      title: "Upgrade Subscription",
      body: <UpgradeModal hideExplanation={false} />,
      hideButtons: true,
      helpTitle: "Subscriptions",
      helpBody: <SubscriptionsHelp />,
    });
  };

  const navLinkItems1: NavItem[] = [
    {
      title: "Dashboard",
      route: "/dashboard",
      icon: faGauge,
      isExternal: false,
      display: true,
    },
    {
      title: "Properties",
      route: "/properties",
      icon: faHouse,
      isExternal: false,
      display: true,
    },
    {
      title: "Sequences",
      route: "/sequences",
      icon: faEnvelope,
      isExternal: false,
      display: true,
    },
    {
      title: "Watchlist",
      route: "/watchlist",
      icon: faEye,
      isExternal: false,
      display: true,
    },
  ];

  const navLinkItems2: NavItem[] = [
    {
      title: "Team",
      route: "/team",
      icon: faUserGroup,
      isExternal: false,
      onClick:
        !(subscription?.canTeam ?? false) && !(team?.isDemo ?? false)
          ? () => handleUpgrade()
          : () => {},
      isDisabled: !(subscription?.canTeam ?? false) && !(team?.isDemo ?? false),
      badge:
        subscription && appSubscriptions ? (
          !(subscription.canTeam ?? false) && !(team?.isDemo ?? false) ? (
            <SubscriptionBadge
              subscription={
                getNextSubscription(subscription, appSubscriptions, true)
                  ?.name || "Pro"
              }
              onClick={() => handleUpgrade()}
              className={
                "border border-icon-light px-1.5 py-0.5 text-sm text-icon-light dark:border-icon-dark dark:text-icon-dark xs:px-1 md:px-2 "
              }
            />
          ) : null
        ) : null,
      display: true,
    },
    {
      title: "Brokers",
      route: "/brokers",
      icon: faHandshake,
      // badge: <NewBadge />,
      isExternal: false,
      display: true,
    },
    {
      title: `${app?.dashName}`,
      route: `/${app?.dashName.toLowerCase()}`,
      icon: faCar,
      isExternal: false,
      display: true,
    },
    {
      title: "Settings",
      route: "/settings",
      icon: faGear,
      isExternal: false,
      display: true,
    },
  ];

  const linkItems: NavItem[] = [
    {
      route: "/",
      icon: faArrowRightFromBracket,
      title: "Logout",
      onClick: handleLogout,
      isExternal: false,
      display: true,
    },
    {
      route: "/support",
      icon: faHeadset,
      title: "Support",
      isExternal: false,
      display: true,
    },
    {
      route: process.env.FEEDBEAR_URL || "https://sendfuse.feedbear.com/",
      icon: faComment,
      title: "Feedback",
      isExternal: true,
      display: app?.id === 1,
    },
    {
      route: "/roadmap",
      icon: faRocket,
      title: `Version ${process.env.REACT_APP_VERSION}`,
      isExternal: false,
      display: true,
    },
  ];

  return (
    <>
      <ul className="flex min-w-full list-none flex-col ">
        {navLinkItems1.map(
          ({ icon, title, route, onClick, isExternal, display }: NavItem) => {
            if (display) {
              return (
                <li key={`nav-${route}-1`} className="items-center">
                  <NavLinkItem
                    icon={icon}
                    title={title}
                    route={route}
                    onClick={onClick || closeNav}
                    isExternal={isExternal}
                  />
                </li>
              );
            } else {
              return <div key={`nav-${route}-no-display`}></div>;
            }
          }
        )}

        <li>
          <hr className="my-2.5 min-w-full border-icon-light dark:border-icon-dark" />
        </li>

        {navLinkItems2.map(
          ({
            icon,
            title,
            route,
            onClick,
            isExternal,
            isDisabled,
            badge,
            display,
          }: NavItem) => {
            if (display) {
              return (
                <li key={`nav-${route}-2`} className="items-center">
                  <NavLinkItem
                    icon={icon}
                    title={title}
                    route={route}
                    onClick={onClick || closeNav}
                    isExternal={isExternal}
                    isDisabled={isDisabled}
                    badge={badge}
                  />
                </li>
              );
            } else {
              return <div key={`nav-${route}-no-display`}></div>;
            }
          }
        )}

        <li>
          <hr className="mb-3 mt-1 min-w-full border-icon-light dark:border-icon-dark" />
        </li>
      </ul>

      <ul className="">
        {linkItems.map(
          ({ route, title, icon, onClick, isExternal, display }: NavItem) => {
            if (display) {
              return (
                <li key={`nav-${route}-3`} className="mb-2 items-center">
                  <LinkItem
                    route={route}
                    icon={icon}
                    title={title}
                    onClick={onClick || closeNav}
                    isExternal={isExternal}
                  />
                </li>
              );
            } else {
              return <div key={`nav-${route}-no-display`}></div>;
            }
          }
        )}
      </ul>
    </>
  );
};

export default SideNavItems;

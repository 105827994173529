import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { templateLettersApi } from "src/api";
import { TemplateLetter } from "src/interfaces/templateLetter";
import useErrorHandler from "src/hooks/private/useErrorHandler";

interface TemplateContextInterface {
  templateLetters: TemplateLetter[];
  fetchTemplateLetters: () => void;
}

const TemplateContextDefaults: TemplateContextInterface = {
  templateLetters: [],
  fetchTemplateLetters: () => {},
};

const TemplateLettersContext = React.createContext<TemplateContextInterface>(
  TemplateContextDefaults
);

interface TemplateLettersProviderProps {}

export const TemplateLettersProvider = ({
  children,
}: PropsWithChildren<TemplateLettersProviderProps>) => {
  const [templateLetters, setTemplateLetters] = useState<TemplateLetter[]>([]);

  const { handleError } = useErrorHandler();

  const fetchTemplateLetters = useCallback(async () => {
    try {
      const templateLetters = await templateLettersApi.getTemplateLetters();
      setTemplateLetters(
        (templateLetters || [])
          .map((t): TemplateLetter => ({ ...t, type: "letter" as const }))
          .sort((a, b) => a.name.localeCompare(b.name))
      );
    } catch (err: any) {
      handleError(err);
    }
  }, [handleError]);

  useEffect(() => {
    fetchTemplateLetters();
  }, [fetchTemplateLetters]);

  return (
    <TemplateLettersContext.Provider
      value={{
        templateLetters,
        fetchTemplateLetters,
      }}
    >
      {children}
    </TemplateLettersContext.Provider>
  );
};

export default TemplateLettersContext;

import React, { useCallback, useState } from "react";
import useTemplatePostcardsContext from "src/hooks/private/useTemplatePostcardsContext";
import { TemplatePostcard as TemplatePostcardType } from "src/interfaces/templatePostcard";
import { TemplateLetter as TemplateLetterType } from "src/interfaces/templateLetter";
import TemplatePostcardHolder from "./TemplatePostcardHolder";
import useViewport from "src/hooks/useViewport";
import "src/css/hideScrollbar.css";
import { TemplateID } from "src/interfaces/campaign";
import useTemplateLettersContext from "src/hooks/private/useTemplateLettersContext";
import { TemplateLetterViewer } from "./TemplateLetterViewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faEnvelopesBulk,
} from "@fortawesome/free-solid-svg-icons";
import useTeamContext from "src/hooks/private/useTeamContext";
import useAuth from "src/hooks/useAuth";

interface TemplateSelectProps {
  type: "absentee" | "occupied";
  sequenceIndex: number;
  onTemplateSelect: (
    sequenceIndex: number,
    templateId: number,
    mailType: string
  ) => void;
  selectedTemplate: TemplateID;
  propertyID?: number;
}

const TemplateSelect = ({
  type,
  sequenceIndex,
  onTemplateSelect,
  selectedTemplate,
  propertyID,
}: TemplateSelectProps) => {
  // @TODO we need another context to handle & retrieve letter's templates list
  const { templatePostcards } = useTemplatePostcardsContext();

  const { templateLetters } = useTemplateLettersContext();

  const { width } = useViewport();

  const { currentUser } = useAuth();

  const { team } = useTeamContext();

  const [mailType, setMailType] = useState<"postcard" | "letter">("postcard");

  const handleTemplateClick = useCallback(
    (id: number) => {
      // Adding the mailtype to build templateIDs object { id: templateID, type: "postcard" or "letter" }
      onTemplateSelect(sequenceIndex, id, mailType);
    },
    [mailType, onTemplateSelect, sequenceIndex]
  );

  return (
    <>
      <div className="relative mb-2 rounded-md bg-card-light px-4 py-2 shadow-xl dark:bg-card-dark">
        <div className="absolute">
          <span className="text-neutral-400 dark:text-neutral-400">
            {width > 500 ? "Mail #" : "#"}
          </span>
          <span className="font-semibold text-text-dark dark:text-text-light">
            {sequenceIndex + 1}
          </span>
        </div>
        <div className="flex items-center justify-center gap-2">
          <button
            value="postcard"
            onClick={() => setMailType("postcard")}
            className={
              mailType === "postcard"
                ? "btn btn-secondary btn-sm text-text-light hover:bg-secondary"
                : "btn btn-ghost btn-sm text-base-100 dark:text-text-light"
            }
          >
            <FontAwesomeIcon icon={faAddressCard} />
            Postcard
          </button>
          <button
            value="letter"
            onClick={() => setMailType("letter")}
            className={
              mailType === "letter"
                ? "btn btn-secondary btn-sm text-text-light hover:bg-secondary"
                : "btn btn-ghost btn-sm text-base-100 dark:text-text-light"
            }
          >
            <FontAwesomeIcon icon={faEnvelopesBulk} />
            Letter
          </button>
        </div>
        <div className="hide-scrollbar mt-2 grid w-full auto-cols-max grid-flow-col items-center justify-start gap-2 overflow-x-auto">
          {mailType === "postcard" &&
            (templatePostcards || [])
              .filter((temp: TemplatePostcardType) => temp.target === type)
              .map((template: TemplatePostcardType) => (
                <div key={`$-temp-${template.id}`}>
                  <TemplatePostcardHolder
                    template={template}
                    pointLoader
                    propertyID={propertyID}
                  />
                  <div className="form-control flex w-full items-center justify-center">
                    <label className="label cursor-pointer">
                      <span className="label-text"></span>
                      <input
                        type="checkbox"
                        checked={
                          selectedTemplate.id !== -1 &&
                          selectedTemplate.id === template.id
                        }
                        className="checkbox-secondary checkbox"
                        onClick={() => handleTemplateClick(template.id)}
                        onChange={() => {}}
                      />
                    </label>
                  </div>
                </div>
              ))}
          {mailType === "letter" &&
            (templateLetters || [])
              .filter((temp: TemplateLetterType) => temp.target === type)
              .map((template: TemplateLetterType) => (
                <div key={`$-temp-${template.id}`} className="pt-2.5">
                  <TemplateLetterViewer
                    template={template}
                    userID={currentUser?.id || team?.leaderID || 0}
                    propertyID={propertyID}
                  />
                  <div className="form-control flex w-full items-center justify-center">
                    <label className="label cursor-pointer">
                      <span className="label-text"></span>
                      <input
                        type="checkbox"
                        checked={
                          selectedTemplate.id !== -1 &&
                          selectedTemplate.id === template.id
                        }
                        className="checkbox-secondary checkbox  "
                        onClick={() => handleTemplateClick(template.id)}
                        onChange={() => {}}
                      />
                    </label>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </>
  );
};

export default TemplateSelect;

import React from "react";
import useTemplateLettersContext from "src/hooks/private/useTemplateLettersContext";
import useCarousel from "src/hooks/useCarousel";
import CarouselHeader from "../carousel/CarouselHeader";
import { TemplateLetter } from "src/interfaces/templateLetter";
import { TemplateLetterViewer } from "./TemplateLetterViewer";
import useAuth from "src/hooks/useAuth";

const LettersTab = () => {
  const { templateLetters } = useTemplateLettersContext();

  const carousel = ["Absentee Owner", "Owner Occupied"];
  const { carouselIndex, carouselActions } = useCarousel(carousel);

  const type =
    carousel[carouselIndex] === "Absentee Owner" ? "absentee" : "occupied";

  const { currentUser } = useAuth();
  return (
    <>
      {currentUser && (
        <>
          <CarouselHeader
            title={carousel[carouselIndex]}
            {...carouselActions}
          />
          <div className="mb-20 mt-4 grid grid-cols-1 gap-12 md:grid-cols-2 lg:mb-0 lg:grid-cols-2 xl:grid-cols-3">
            {(templateLetters || [])
              .filter((temp: TemplateLetter) => temp.target === type)
              .map((template: TemplateLetter) => (
                <div
                  key={`$-temp-${template.id}`}
                  className="flex justify-center"
                >
                  <TemplateLetterViewer
                    template={template}
                    userID={currentUser?.id}
                    // propertyID={propertyID}
                  />
                </div>
              ))}
          </div>
        </>
      )}
    </>
  );
};

export default LettersTab;
